import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    height: '100%',
    minHeight: 300,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1000,
  },
  mapLegend: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    zIndex: 1000,
    maxHeight: 200,
    overflowY: 'auto',
    boxShadow: theme.shadows[2],
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  colorSquare: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
    borderRadius: 2,
  },
  markerIcon: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    fontSize: 16,
  }
}));

// Add global styles for Leaflet tooltips to make them semi-transparent
const addGlobalStyles = () => {
  if (typeof document !== 'undefined') {
    const style = document.createElement('style');
    style.textContent = `
      .territory-label {
        background-color: rgba(255, 255, 255, 0.7) !important;
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        font-weight: normal !important;
        box-shadow: none !important;
        pointer-events: none !important;
      }
    `;
    document.head.appendChild(style);
  }
};

const TerritoryMapPreview = ({ url }) => {
  const classes = useStyles();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const drawnItemsRef = useRef(null);
  const markersLayerRef = useRef(null);
  
  const [loading, setLoading] = useState(true);
  const [polygons, setPolygons] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeeColorMap, setEmployeeColorMap] = useState({});

  // Generate distinct colors for employees
  const generateDistinctColorMap = (employees) => {
    const colorMap = {};
    const total = employees.length;
  
    // Generate shuffled hue steps for max visual separation
    const hueSteps = [];
    for (let i = 0; i < total; i++) {
      const hue = Math.round((360 / total) * i);
      hueSteps.push(hue);
    }
  
    // Shuffle hue order to spread visually similar colors apart
    const visuallyDistinctOrder = [0, 180, 90, 270, 45, 225, 135, 315]; // works great for up to 8
    const hues = hueSteps.map((_, i) => visuallyDistinctOrder[i % visuallyDistinctOrder.length]);
  
    employees.forEach((emp, index) => {
      const hue = hues[index % hues.length]; // wrap if more than 8
      colorMap[emp._id] = `hsl(${hue}, 70%, 50%)`;
    });
  
    return colorMap;
  };

  // Add employee name label to polygon
  const addLabelToPolygon = (layer, employeeId, employees) => {
    if (!employeeId) return;
    
    const employee = employees.find(emp => emp._id === employeeId);
    if (!employee) return;
    
    const center = layer.getCenter();
    
    // Create a tooltip with employee name - semi-transparent and lower z-index
    layer.bindTooltip(`${employee.firstName} ${employee.lastName}`, {
      permanent: true,
      direction: 'center',
      className: 'territory-label',
      opacity: 0.7,
      zIndexOffset: -1000, // Ensure labels are below markers
    }).openTooltip();
  };

  // Create a custom marker icon
  const createCustomMarkerIcon = (L, color) => {
    return L.divIcon({
      className: 'custom-marker-icon',
      html: `<div style="
        background-color: ${color};
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 2px solid white;
        box-shadow: 0 0 4px rgba(0,0,0,0.5);
      "></div>`,
      iconSize: [18, 18],
      iconAnchor: [9, 9],
    });
  };

  // Add a marker for an employee's last location
  const addEmployeeLocationMarker = (L, map, markersLayer, employee, color) => {
    // Check if employee has location data
    if (employee.crew && employee.crew.lastLocation && employee.crew.lastLocation.coords) {
      const { latitude, longitude } = employee.crew.lastLocation.coords;
      
      // Create marker with custom icon using the employee's assigned color
      const icon = createCustomMarkerIcon(L, color);
      
      // Create marker at the employee's location
      const marker = L.marker([latitude, longitude], {
        icon: icon,
        zIndexOffset: 2000, // Higher z-index to ensure markers are above everything else
      });
      
      // Add tooltip with employee name and timestamp
      const timestamp = employee.crew.lastLocation.timestamp 
        ? new Date(employee.crew.lastLocation.timestamp).toLocaleString()
        : 'Unknown';
        
      marker.bindTooltip(`
        <strong>${employee.firstName} ${employee.lastName}</strong><br>
        Last seen: ${timestamp}
      `);
      
      // Add marker to layer group
      markersLayer.addLayer(marker);
    }
  };

  // Fetch territories and employees
  const fetchTerritories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}/api/hmi/genericGetRequest?type=IRRIGATION_TERRITORY_DETAILS`);
      
      const {
        existingTerritories,
        irrigationTechs
      } = response?.data?.data;
      
      let colorMap = {};
      
      if (irrigationTechs && irrigationTechs.length) {
        setEmployees(irrigationTechs);
        
        // Generate color map
        colorMap = generateDistinctColorMap(irrigationTechs);
        setEmployeeColorMap(colorMap);
      }
      
      if (existingTerritories && existingTerritories.length) {
        // Transform the territory data
        const transformedTerritories = existingTerritories.map(territory => {
          // Extract coordinates from the GeoJSON format and convert to {lat,lng} format
          const coordinates = territory.geometry.coordinates.map(ring =>
            ring.map(coord => ({
              lng: coord[0],
              lat: coord[1]
            }))
          );
          
          return {
            _id: territory._id,
            employeeId: territory.employeeId,
            coordinates: coordinates,
            area: territory.area,
            active: territory.active
          };
        });
        
        setPolygons(transformedTerritories);
        
        // Initialize the map once we have the data
        initializeMap(transformedTerritories, irrigationTechs, colorMap);
      }
    } catch (error) {
      console.error('Error fetching territories:', error);
    } finally {
      setLoading(false);
    }
  };

  // Initialize Leaflet map
  const initializeMap = (territories, employees, colorMap) => {
    // Dynamically import Leaflet to avoid SSR issues
    import('leaflet').then(L => {
      // Clean up existing map instance
      if (mapRef.current) {
        mapRef.current.remove();
      }
      
      // Create a new map instance
      const map = L.map(mapContainerRef.current, {
        center: [37.7749, -122.4194], // Default center (San Francisco)
        zoom: 10,
        zoomControl: true,
        attributionControl: false,
      });
      
      // Add OpenStreetMap tile layer
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }).addTo(map);
      
      // Create a feature group for the polygons
      const drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);
      
      // Create a separate layer group for employee location markers
      const markersLayer = new L.FeatureGroup();
      map.addLayer(markersLayer);
      
      mapRef.current = map;
      drawnItemsRef.current = drawnItems;
      markersLayerRef.current = markersLayer;
      
      // Add territories to the map
      if (territories.length > 0) {
        const bounds = new L.LatLngBounds();
        
        territories.forEach(territory => {
          const latLngs = territory.coordinates.map(ring =>
            ring.map(coord => L.latLng(coord.lat, coord.lng))
          );
          
          const layer = L.polygon(latLngs);
          layer._id = territory._id;
          layer.employeeId = territory.employeeId;
          
          if (territory.employeeId) {
            layer.setStyle({
              color: colorMap[territory.employeeId] || '#3388ff',
              fillOpacity: 0.3
            });
          }
          
          // Extend bounds to include this polygon
          latLngs[0].forEach(latlng => bounds.extend(latlng));
          
          // Add layer to the map first
          drawnItems.addLayer(layer);
          
          // Now add the label to the polygon after it's been added to the map
          if (territory.employeeId) {
            addLabelToPolygon(layer, territory.employeeId, employees);
          }
        });
        
        // Add employee location markers
        // employees.forEach(employee => {
        //   if (employee.crew && employee.crew.lastLocation && employee.crew.lastLocation.coords) {
        //     // Use the employee's assigned color for their marker
        //     const color = colorMap[employee._id] || '#3388ff';
            
        //     // Add marker for employee's last location
        //     addEmployeeLocationMarker(L, map, markersLayer, employee, color);
            
        //     // Extend bounds to include this marker location
        //     if (employee.crew.lastLocation.coords.latitude && employee.crew.lastLocation.coords.longitude) {
        //       bounds.extend([
        //         employee.crew.lastLocation.coords.latitude,
        //         employee.crew.lastLocation.coords.longitude
        //       ]);
        //     }
        //   }
        // });
        
        // Fit map to the bounds of all territories and markers
        if (bounds.isValid()) {
          map.fitBounds(bounds, { padding: [20, 20] });
        }
      }
    });
  };

  const fetchLastLocations = async () => {
    console.log('Fetch Last Locations...')
    try {
      const response = await axios.get(`${url}/api/hmi/genericGetRequest?type=IRRIGATION_TECH_LOCATIONS`);
      console.log('fetch last loc', response)
      const {
        irrigationTechs,
        locationData,
        success
      } = response.data
      if (!success) {
        console.log('oh fuck...', response.data)
        return
      }
      console.log('Got our loc stuff..', locationData.length)
      if (mapRef.current) {
        const map = mapRef.current
        const markersLayer = markersLayerRef.current
        const bounds = new L.LatLngBounds()
        
        irrigationTechs.forEach(employee => {
          let finalLocation = null;
        
          // Get user id
          const userId = employee.user && employee.user._id;
        
          // Find matching locationData entry for this employee
          const matchingLocation = locationData.find(loc => loc.user && loc.user._id === userId);
        
          // Grab stored crew location
          const storedLocation = employee.crew?.lastLocation;
        
          // Determine which location to use (compare timestamps if both exist)
          if (matchingLocation && storedLocation) {
            const storedTime = new Date(storedLocation.timestamp || 0);
            const locDataTime = new Date(matchingLocation.created_at || 0);
        
            finalLocation = locDataTime > storedTime
              ? {
                  coords: {
                    latitude: parseFloat(matchingLocation.lat),
                    longitude: parseFloat(matchingLocation.long)
                  }
                }
              : storedLocation;
          } else if (matchingLocation) {
            finalLocation = {
              coords: {
                latitude: parseFloat(matchingLocation.lat),
                longitude: parseFloat(matchingLocation.long)
              }
            };
          } else if (storedLocation) {
            finalLocation = storedLocation;
          }
        
          // Only proceed if we got a valid location
          if (finalLocation?.coords) {
            const color = employeeColorMap[employee._id] || '#3388ff';
            addEmployeeLocationMarker(L, map, markersLayer, employee, color, finalLocation);
        
            if (finalLocation.coords.latitude && finalLocation.coords.longitude) {
              bounds.extend([
                finalLocation.coords.latitude,
                finalLocation.coords.longitude
              ]);
            }
          }
        });
        
      }
    } catch (e) {
      console.log('329... Error', e)
    }
  }

  // Initialize map on component mount
  useEffect(() => {
    if (!url) return;
    
    // Add global styles for Leaflet tooltips
    addGlobalStyles();
    
    fetchTerritories();
    fetchLastLocations()
    // Cleanup on unmount
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [url]);
  
  // Handle window resize to make sure map fits container
  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Render map legends
  const renderLegend = () => {
    const assignedEmployees = employees.filter(emp => 
      polygons.some(poly => poly.employeeId === emp._id) || 
      (emp.crew && emp.crew.lastLocation && emp.crew.lastLocation.coords)
    );
    
    return (
      <Box className={classes.mapLegend}>
        <Typography variant="subtitle2" gutterBottom>Assigned Technicians</Typography>
        {assignedEmployees.map(emp => (
          <div key={emp._id} className={classes.legendItem}>
            <div 
              className={classes.colorSquare} 
              style={{ backgroundColor: employeeColorMap[emp._id] || '#3388ff' }} 
            />
            <Typography variant="caption">
              {emp.firstName} {emp.lastName}
              {emp.crew && emp.crew.lastLocation && emp.crew.lastLocation.coords && (
                <span> • <span className={classes.markerIcon}>📍</span> Last seen</span>
              )}
            </Typography>
          </div>
        ))}
      </Box>
    );
  };

  return (
    <Box className={classes.mapContainer} ref={mapContainerRef}>
      {loading && (
        <Box className={classes.loadingOverlay}>
          <CircularProgress />
        </Box>
      )}
      {employees.length > 0 && !loading && renderLegend()}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  url: state.url,
});

export default connect(mapStateToProps)(TerritoryMapPreview);
