import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Drawer,
  Divider,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  CircularProgress,
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { 
  Timeline, 
  Edit, 
  Save, 
  Menu as MenuIcon, 
  Delete 
} from '@material-ui/icons';
import L from "leaflet";
import "leaflet/dist/leaflet.css";
// Import Leaflet-Geoman (formerly Leaflet-PM) for better snapping support
// import 'leaflet-geoman-free';
// import 'leaflet-geoman-free/dist/leaflet-geoman.css';
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import axios from 'axios';
import { connect } from 'react-redux';

const DRAWER_WIDTH = 300;

// Define styles first
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(10)
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(8), // Space for the app bar
    height: '100vh',
    overflow: 'hidden',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mapContainer: {
    height: 'calc(100vh - 80px)',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: 4,
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1
  },
  mapWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  buttonGroup: {
    marginBottom: theme.spacing(2)
  },
  colorIndicator: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  sectionHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  '@global': {
    '.polygon-label': {
      background: 'transparent',
      border: 'none',
      boxShadow: 'none',
      fontWeight: 'bold',
      fontSize: '12px',
      color: '#000',
      textShadow: '1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white',
      pointerEvents: 'none' // Make sure it doesn't interfere with clicks
    }
  }
}));

const addLabelToPolygon = (layer, employeeId, employees) => {
  // Find the employee name based on employeeId
  console.log('addLabelToPolygon...emp:', employeeId, employees.length)
  const employee = employees.find(emp => emp._id === employeeId);
  const labelText = employee ? `${employee.firstName} ${employee.lastName}` : 'Unassigned';
  
  // Calculate the center of the polygon for label placement
  const bounds = layer.getBounds();
  const center = bounds.getCenter();
  
  // Create a tooltip permanently attached to the center of the polygon
  layer.bindTooltip(labelText, {
    permanent: true,
    direction: 'center',
    className: 'polygon-label'
  });
};

const generateDistinctColorMap = (employees) => {
  const colorMap = {};
  const total = employees.length;

  // Generate shuffled hue steps for max visual separation
  const hueSteps = [];
  for (let i = 0; i < total; i++) {
    const hue = Math.round((360 / total) * i);
    hueSteps.push(hue);
  }

  // Shuffle hue order to spread visually similar colors apart
  const visuallyDistinctOrder = [0, 180, 90, 270, 45, 225, 135, 315]; // works great for up to 8
  const hues = hueSteps.map((_, i) => visuallyDistinctOrder[i % visuallyDistinctOrder.length]);

  employees.forEach((emp, index) => {
    const hue = hues[index % hues.length]; // wrap if more than 8
    colorMap[emp._id] = `hsl(${hue}, 70%, 50%)`;
  });

  return colorMap;
};



const generateEmployeeColorMap = (employees) => {
  const colorMap = {};
  const total = employees.length;

  employees.forEach((emp, i) => {
    const hue = (i * (360 / total)) % 360;
    const color = `hsl(${hue}, 70%, 50%)`;
    colorMap[emp._id] = color;
  });
  console.log('Color Map', colorMap)
  return colorMap;
};


const EmployeePolygonMap = (props) => {
  console.log('Employee Polygon map..', props);

  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const drawnItemsRef = useRef(null);
  
  // State
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [polygons, setPolygons] = useState([]);
  const [saving, setSaving] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '' });
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [isSnappingEnabled, setIsSnappingEnabled] = useState(true);
  const [drawingMode, setDrawingMode] = useState(false);
  const [editingMode, setEditingMode] = useState(false);
  const [employeeColorMap, setEmployeeColorMap] = useState({});
  const [loading, setLoading] = useState(false)
  const [selectedEmployeeInList, setSelectedEmployeeInList] = useState(null);

  // Responsive behavior
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Apply styles
  const classes = useStyles();
  
  // Use effect to handle mobile drawer state
  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile]);

  // Initialize map
  useEffect(() => {
    // Load any existing polygons from the server
    fetchPolygons();
    
    if (mapContainerRef.current && !mapRef.current) {
      initializeMap();
    }
    
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
        drawnItemsRef.current = null;
      }
    };
  }, []);

  // Invalidate map size when drawer opens/closes
  useEffect(() => {
    if (mapRef.current) {
      // Give time for the drawer animation to complete
      setTimeout(() => {
        mapRef.current.invalidateSize();
      }, 300);
    }
  }, [drawerOpen]);

  // Initialize the map and its controls
  const initializeMap = () => {
    // Create the map with Google Hybrid layer (streets + satellite)
    mapRef.current = L.map(mapContainerRef.current, {
      center: [33.55, -112.0], // Default center (Phoenix, AZ)
      zoom: 12,
      zoomControl: true,
    });

    // Add Google Hybrid layer for street names visibility
    L.tileLayer("https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}", {
      attribution: "&copy; Google Maps",
      maxZoom: 20,
      minZoom: 0
    }).addTo(mapRef.current);

    // Initialize the feature group to store drawn items
    const drawnItems = new L.FeatureGroup();
    mapRef.current.addLayer(drawnItems);
    drawnItemsRef.current = drawnItems;

    // Initialize Leaflet-Geoman with global options
    mapRef.current.pm.setGlobalOptions({
      // Snapping settings
      snappable: isSnappingEnabled,
      snapDistance: 20, // Distance in pixels for snapping
      snapSegment: true, // Allow snapping to segments, not just vertices
      
      // Prevent overlapping
      allowSelfIntersection: false,
      
      // Visual settings
      templineStyle: {
        color: '#3388ff',
      },
      hintlineStyle: {
        color: '#3388ff',
        dashArray: [5, 5],
      },
      pathOptions: {
        color: '#3388ff',
        fillColor: '#3388ff',
        fillOpacity: 0.2,
      }
    });

    // Set up event handlers for Leaflet-Geoman
    mapRef.current.on('pm:create', (e) => {
      const layer = e.layer;
      
      // Add the created polygon to our feature group
      drawnItems.addLayer(layer);
      
      // Add a property to store the employee ID
      layer.employeeId = null;
      
      // Add click handler to select this polygon
      layer.on('click', (event) => {
        // Stop propagation to prevent map click
        L.DomEvent.stopPropagation(event);
        
        // Only open dialog if not in editing mode
        if (!editingMode && !drawingMode) {
          setSelectedPolygon(layer);
          setSelectedEmployee(layer.employeeId || '');
          setDialogOpen(true);
        }
      });
      
      // Turn off drawing mode
      disableDrawMode();
      
      // Update polygons state
      updatePolygonsState();
      
      // Ask to assign an employee immediately
      setSelectedPolygon(layer);
      setSelectedEmployee('');
      setDialogOpen(true);
    });

    // Handle edited polygons
    mapRef.current.on('pm:update', () => {
      // Update polygons in state after edit
      updatePolygonsState();
      
      setNotification({
        open: true,
        message: 'Polygon edited! Remember to save your changes.'
      });
    });

    // Handle removed polygons
    mapRef.current.on('pm:remove', () => {
      // Update polygons in state after removal
      updatePolygonsState();
      
      setNotification({
        open: true,
        message: 'Polygon removed! Remember to save your changes.'
      });
    });
  };

  // Toggle snapping functionality
  const toggleSnapping = () => {
    const newSnappingState = !isSnappingEnabled;
    setIsSnappingEnabled(newSnappingState);
    
    // Update Leaflet-Geoman global options
    if (mapRef.current && mapRef.current.pm) {
      mapRef.current.pm.setGlobalOptions({
        snappable: newSnappingState
      });
    }
    
    setNotification({
      open: true,
      message: `Snapping ${newSnappingState ? 'enabled' : 'disabled'}`
    });
  };

  // Fetch existing polygons from the server
  const fetchPolygons = async () => {
    try {
      const response = await axios.get(`${props.url}/api/hmi/genericGetRequest?type=IRRIGATION_TERRITORY_DETAILS`);
      console.log('266...IRRIGATION_TERRITORY_DETAILS', response);
      const {
        existingTerritories,
        irrigationTechs
      } = response?.data?.data;
      
      let colorMap = {};
    
      if (irrigationTechs && irrigationTechs.length) {
        console.log('Irrigation Techs', irrigationTechs);
        setEmployees(irrigationTechs);
        
        // Generate color map here but also keep a local reference
        colorMap = generateDistinctColorMap(irrigationTechs);
        setEmployeeColorMap(colorMap);
      }
      
      if (existingTerritories && existingTerritories.length) {
        console.log('Existing Territories', existingTerritories);
        
        // Transform the territory data to match the expected frontend format
        const transformedTerritories = existingTerritories.map(territory => {
          // Extract coordinates from the GeoJSON format and convert to {lat,lng} format
          const coordinates = territory.geometry.coordinates.map(ring => 
            ring.map(coord => ({
              // GeoJSON uses [longitude, latitude] order; we need to swap to {lat, lng}
              lng: coord[0],
              lat: coord[1]
            }))
          );
          
          return {
            _id: territory._id,
            employeeId: territory.employeeId,
            coordinates: coordinates,
            // Include other properties as needed
            area: territory.area,
            active: territory.active
          };
        });
        
        setPolygons(transformedTerritories);
        
        // Add them to the map if it's initialized
        if (mapRef.current && drawnItemsRef.current) {
          transformedTerritories.forEach(polygon => {
            // Use the transformed coordinates directly
            const latLngs = polygon.coordinates.map(ring => 
              ring.map(coord => L.latLng(coord.lat, coord.lng))
            );
            
            const layer = L.polygon(latLngs);
            layer._id = polygon._id; // Store reference to the original ID
            layer.employeeId = polygon.employeeId;
            console.log('Set polygon color...', polygon)
            const color =  employeeColorMap[polygon.employeeId] || '#3388ff'
            console.log('392:: color:', color)

            if (polygon.employeeId) {
              layer.setStyle({ 
                color: colorMap[polygon.employeeId] || '#3388ff',
                fillOpacity: 0.3
              });
              
              // Add the label to the polygon
              addLabelToPolygon(layer, polygon.employeeId, irrigationTechs);
            }
            
            // Add click handler
            layer.on('click', (event) => {
              // Stop propagation to prevent map click
              L.DomEvent.stopPropagation(event);
              
              // Only open dialog if not in editing mode
              if (!editingMode && !drawingMode) {
                setSelectedPolygon(layer);
                setSelectedEmployee(layer.employeeId || '');
                setDialogOpen(true);
              }
            });
            
            drawnItemsRef.current.addLayer(layer);
            
            // Make layer editable with Leaflet-Geoman
            layer.pm.enable({
              allowSelfIntersection: false,
              snappable: isSnappingEnabled,
              snapDistance: 20,
            });
            
            // But disable editing mode initially
            layer.pm.disable();
          });
        }
      }
    } catch (error) {
      console.error('Error fetching polygons:', error);
      setNotification({
        open: true,
        message: 'Failed to load existing polygons'
      });
    }
  };

  // Update the polygons state based on what's currently drawn on the map
  const updatePolygonsState = () => {
    if (!drawnItemsRef.current) return;
    
    const currentPolygons = [];
    drawnItemsRef.current.eachLayer(layer => {
      if (layer instanceof L.Polygon) {
        currentPolygons.push({
          employeeId: layer.employeeId,
          coordinates: extractLatLngs(layer.getLatLngs())
        });
      }
    });
    
    setPolygons(currentPolygons);
  };

  // Helper to extract coordinates from Leaflet's nested LatLng objects
  const extractLatLngs = (latLngs) => {
    // Handle nested arrays (multi-polygons)
    if (latLngs[0] instanceof Array) {
      return latLngs.map(ring => 
        ring.map(latlng => ({ lat: latlng.lat, lng: latlng.lng }))
      );
    }
    // Handle simple polygons
    return [latLngs.map(latlng => ({ lat: latlng.lat, lng: latlng.lng }))];
  };

  // Get a color based on employee ID for visualization
  const getColorForEmployee = (employeeId) => {
    console.log('getColorForEmployee', employeeId)
    if (!employeeId) return '#3388ff';
  
    let hash = 0;
    const str = employeeId.toString();
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    const hue = Math.abs(hash) % 360; // hue between 0-359
    return `hsl(${hue}, 70%, 50%)`; // colorful, high-contrast
  };

  
  // Assign an employee to the selected polygon
  const assignEmployee = () => {
    if (selectedPolygon && drawnItemsRef.current) {
      // Update the polygon's employee ID
      selectedPolygon.employeeId = selectedEmployee || null;
      
      // Update the polygon's color based on employee
      selectedPolygon.setStyle({ 
        color: employeeColorMap[selectedEmployee] || '#3388ff',
        fillOpacity: 0.3
      });
      
      // Remove existing tooltip if any
      if (selectedPolygon.getTooltip()) {
        selectedPolygon.unbindTooltip();
      }
      
      // Add updated label if employee is assigned
      if (selectedEmployee) {
        addLabelToPolygon(selectedPolygon, selectedEmployee, employees);
      }
      
      // Update the polygons state
      updatePolygonsState();
      
      setNotification({
        open: true,
        message: `Polygon assigned to ${selectedEmployee ? 'employee' : 'no one'}. Remember to save!`
      });
      
      setDialogOpen(false);
    }
  };

  const saveTerritories = async () => {
    try {
      setLoading(true);
      
      // Get all polygon layers from the map
      const allPolygons = [];
      
      drawnItemsRef.current.eachLayer(layer => {
        if (layer instanceof L.Polygon) {
          // Extract coordinates from Leaflet polygon
          const latLngs = layer.getLatLngs()[0]; // Get first ring of coordinates
          
          // Convert to the format expected by our backend
          const coordinates = [latLngs.map(latLng => ({
            lat: latLng.lat,
            lng: latLng.lng
          }))];
          
          // Create polygon object with all necessary properties
          const polygon = {
            _id: layer._id, // Include ID if it exists (for existing polygons)
            employeeId: layer.employeeId || null,
            coordinates: coordinates
          };
          
          allPolygons.push(polygon);
        }
      });
      
      console.log('Saving territories:', allPolygons);
      
      // Send to backend
      const response = await axios.post(`${props.url}/api/hmi/genericPostRequest`, allPolygons, {
        params: {
          type: 'SAVE_IRRIGATION_TERRITORIES'
        }
      });
      
      if (response.data.success) {
        setNotification({
          open: true,
          message: 'Territories saved successfully',
          severity: 'success'
        });
        
        // Fetch updated territories
        fetchPolygons();
      } else {
        throw new Error(response.data.message || 'Failed to save territories');
      }
    } catch (error) {
      console.error('Error saving territories:', error);
      setNotification({
        open: true,
        message: `Failed to save territories: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Save all polygons to the server
  const savePolygons = async () => {
    setSaving(true);
    console.log('Our polygons', polygons)
    try {
      // Replace with your actual API endpoint
      await axios.post(`${props.url}/api/hmi/genericPostRequest?type=SAVE_IRRIGATION_TERRITORIES`, polygons);
      
      setNotification({
        open: true,
        message: 'Polygons saved successfully!'
      });
    } catch (error) {
      console.error('Error saving polygons:', error);
      setNotification({
        open: true,
        message: 'Failed to save polygons. Please try again.'
      });
    } finally {
      setSaving(false);
    }
  };

  // Clear all drawn polygons
  const clearDrawings = () => {
    if (drawnItemsRef.current) {
      drawnItemsRef.current.clearLayers();
      setPolygons([]);
      
      // Make sure to disable any active editing or drawing
      disableDrawMode();
      disableEditMode();
      setSelectedEmployeeInList(null); // Reset employee selection
      
      setNotification({
        open: true,
        message: 'All polygons cleared'
      });
    }
  };

  // Handle the draw polygon button click - Enable draw mode
  const handleDrawPolygon = () => {
    if (mapRef.current) {
      // Disable edit mode if active
      disableEditMode();
      
      // Enable draw mode
      mapRef.current.pm.enableDraw('Polygon', {
        snappable: isSnappingEnabled,
        snapDistance: 20,
        allowSelfIntersection: false,
        finishOn: 'dblclick', // Finish drawing on double click
        templineStyle: {
          color: '#3388ff',
        },
        hintlineStyle: {
          color: '#3388ff',
          dashArray: [5, 5],
        },
        pathOptions: {
          color: '#3388ff',
          fillColor: '#3388ff',
          fillOpacity: 0.2,
        }
      });
      
      setDrawingMode(true);
      
      // If on mobile, close the drawer when starting to draw
      if (isMobile && drawerOpen) {
        setDrawerOpen(false);
      }
      
      setNotification({
        open: true,
        message: 'Draw mode enabled. Click to create vertices, double-click to finish.'
      });
    }
  };

  // Disable draw mode
  const disableDrawMode = () => {
    if (mapRef.current) {
      mapRef.current.pm.disableDraw();
      setDrawingMode(false);
    }
  };

  // Handle the edit polygons button click - Enable edit mode
  const handleEditPolygons = () => {
    if (mapRef.current && drawnItemsRef.current) {
      // Disable draw mode if active
      disableDrawMode();
      
      // Enable edit mode for all layers
      drawnItemsRef.current.eachLayer(layer => {
        if (layer instanceof L.Polygon) {
          layer.pm.enable({
            snappable: isSnappingEnabled,
            snapDistance: 20,
            allowSelfIntersection: false,
          });
        }
      });
      
      setEditingMode(true);
      
      // If on mobile, close the drawer when starting to edit
      if (isMobile && drawerOpen) {
        setDrawerOpen(false);
      }
      
      setNotification({
        open: true,
        message: 'Edit mode enabled. Click and drag vertices to reshape polygons.'
      });
    }
  };

  // Disable edit mode
  const disableEditMode = () => {
    if (mapRef.current && drawnItemsRef.current) {
      drawnItemsRef.current.eachLayer(layer => {
        if (layer instanceof L.Polygon && layer.pm) {
          layer.pm.disable();
          
          // Restore original styling based on assigned employee
          const color = employeeColorMap[layer.employeeId] || '#3388ff';
          layer.setStyle({ 
            color: color,
            fillOpacity: 0.3,
            weight: 3
          });
        }
      });
      
      setEditingMode(false);
      setSelectedEmployeeInList(null); // Reset employee selection when disabling edit mode
    }
  };

  // Toggle edit mode
  const toggleEditMode = () => {
    if (editingMode) {
      disableEditMode();
      setNotification({
        open: true,
        message: 'Edit mode disabled.'
      });
    } else {
      handleEditPolygons();
    }
  };

  const handleEmpPress = (emp) => {
    console.log('handleEmpPress', emp);
    
    // If the same employee is clicked again, deselect them
    if (selectedEmployeeInList === emp._id) {
      // Deselect all polygons
      if (drawnItemsRef.current) {
        drawnItemsRef.current.eachLayer(layer => {
          if (layer instanceof L.Polygon) {
            // Restore original styling based on assigned employee
            const color = employeeColorMap[layer.employeeId] || '#3388ff';
            layer.setStyle({ 
              color: color,
              fillOpacity: 0.3,
              weight: 3
            });
            
            // Disable editing for this polygon
            if (layer.pm) {
              layer.pm.disable();
            }
          }
        });
      }
      
      setSelectedEmployeeInList(null);
      setNotification({
        open: true,
        message: 'Employee polygon selection cleared'
      });
      
      // Disable edit mode
      setEditingMode(false);
      return;
    }
    
    // If we're in drawing mode, disable it
    disableDrawMode();
    
    // Enable edit mode if it's not already enabled
    if (!editingMode) {
      setEditingMode(true);
    }
    
    // Select the employee
    setSelectedEmployeeInList(emp._id);
    
    // Highlight and make editable only this employee's polygons
    if (drawnItemsRef.current) {
      let foundPolygons = 0;
      
      drawnItemsRef.current.eachLayer(layer => {
        if (layer instanceof L.Polygon) {
          if (layer.employeeId === emp._id) {
            // Highlight this employee's polygons
            layer.setStyle({ 
              color: employeeColorMap[emp._id] || '#3388ff',
              fillOpacity: 0.5,
              weight: 5
            });
            
            // Enable editing for this polygon
            if (layer.pm) {
              layer.pm.enable({
                snappable: isSnappingEnabled,
                snapDistance: 20,
                allowSelfIntersection: false,
              });
            }
            
            foundPolygons++;
          } else {
            // Fade out other polygons
            layer.setStyle({ 
              color: '#999999',
              fillOpacity: 0.1,
              weight: 1
            });
            
            // Disable editing for other polygons
            if (layer.pm) {
              layer.pm.disable();
            }
          }
        }
      });
      
      // If on mobile, close the drawer when selecting polygons
      if (isMobile && drawerOpen) {
        setDrawerOpen(false);
      }
      
      setNotification({
        open: true,
        message: `Selected ${foundPolygons} polygon${foundPolygons !== 1 ? 's' : ''} assigned to ${emp.firstName} ${emp.lastName}`
      });
    }
  };

  // Component JSX
  return (
    <div className={classes.root}>
      {/* App Bar */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Irrigation Territory Map
          </Typography>
        </Toolbar>
      </AppBar>
      
      {/* Sidebar */}
      <Drawer
        className={classes.drawer}
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* Map Actions */}
        <Typography variant="h6" className={classes.sectionHeader}>Map Tools</Typography>
        <div>
          <Button 
            variant={drawingMode ? "contained" : "outlined"}
            color="primary" 
            fullWidth
            startIcon={<Timeline />}
            onClick={drawingMode ? disableDrawMode : handleDrawPolygon}
            className={classes.buttonGroup}
          >
            {drawingMode ? 'Cancel Drawing' : 'Draw Polygon'}
          </Button>
          
          <Button 
            variant={editingMode ? "contained" : "outlined"} 
            color="primary" 
            fullWidth
            startIcon={<Edit />}
            onClick={toggleEditMode}
            className={classes.buttonGroup}
          >
            {editingMode ? 'Finish Editing' : 'Edit Polygons'}
          </Button>
          
          <Button 
            variant="outlined" 
            color="secondary" 
            fullWidth
            startIcon={<Delete />}
            onClick={clearDrawings}
            className={classes.buttonGroup}
          >
            Clear All
          </Button>
          
          {/* Toggle Snapping Button */}
          <Button 
            variant={isSnappingEnabled ? "contained" : "outlined"}
            color="primary" 
            fullWidth
            onClick={toggleSnapping}
            className={classes.buttonGroup}
          >
            Snapping: {isSnappingEnabled ? 'ON' : 'OFF'}
          </Button>
        </div>
        
        <Divider />
        
        {/* Employee Legend */}
        <Typography variant="h6" className={classes.sectionHeader} >
          Irrigation Territories:
        </Typography>
        <List dense>
          {employees.map(employee => (
           <ListItem 
            key={employee._id} 
            onClick={() => handleEmpPress(employee)} 
            style={{ 
              cursor: 'pointer',
              backgroundColor: selectedEmployeeInList === employee._id ? 'rgba(0, 0, 0, 0.08)' : 'transparent'
            }}
            selected={selectedEmployeeInList === employee._id}
          >
            <div 
              className={classes.colorIndicator}
              style={{
                backgroundColor: employeeColorMap[employee._id] || '#3388ff',
                width: selectedEmployeeInList === employee._id ? 20 : 16,
                height: selectedEmployeeInList === employee._id ? 20 : 16
              }}
            />
            <ListItemText primary={`${employee.firstName} ${employee.lastName}`} />
          </ListItem>
          ))}
        </List>
        
        <Divider />
        
        {/* Save Button */}
        <Box mt={3} mb={2}>
          <Button 
            variant="contained" 
            color="secondary" 
            fullWidth
            startIcon={saving ? <CircularProgress size={24} /> : <Save />}
            onClick={saveTerritories}
            disabled={saving || polygons.length === 0}
          >
            Save All Polygons
          </Button>
        </Box>
      </Drawer>
      
      {/* Main Content */}
      <main
        className={classes.content}
        style={{
          marginLeft: drawerOpen && !isMobile ? 0 : -DRAWER_WIDTH,
        }}
      >
        {/* Map container */}
        <div className={classes.mapWrapper}>
          <div ref={mapContainerRef} className={classes.mapContainer}></div>
        </div>
      </main>
      
      {/* Employee assignment dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Assign Territory to Employee</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="employee-select-label">Select Employee</InputLabel>
            <Select
              labelId="employee-select-label"
              id="employee-select"
              value={selectedEmployee}
              onChange={(e) => setSelectedEmployee(e.target.value)}
            >
              <MenuItem value="">
                <em>Unassigned</em>
              </MenuItem>
              {employees.map(employee => (
                <MenuItem key={employee._id} value={employee._id}>
                  {`${employee.firstName} ${employee.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={assignEmployee} color="primary" variant="contained">
            Assign
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Notification snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
        message={notification.message}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    url: state.url,
    // user: state.user
  }
}

export default connect(mapStateToProps)(EmployeePolygonMap);
