import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Box,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
  IconButton,
  Tabs,
  Tab,
  Chip,
  Badge,
  Tooltip
} from '@material-ui/core';
import {
  People as PeopleIcon,
  Map as MapIcon,
  Build as BuildIcon,
  Assignment as AssignmentIcon,
  Timeline as TimelineIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  Event as EventIcon,
  NavigateNext as NavigateNextIcon,
  LocationOn as LocationOnIcon,
  Business as BusinessIcon,
  Star as StarIcon
} from '@material-ui/icons';
import { 
  PieChart, Pie, Cell, ResponsiveContainer, 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend,
  LineChart, Line, Area, AreaChart
} from 'recharts';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TerritoryMapPreview from './TerritoryMapPreview'
import WorkorderTrendChart from './WorkorderTrend';
// Define styles using Material UI's makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    }
  },
  dashboardTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: 500,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%'
  },
  fixedHeight: {
    height: 240,
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: theme.spacing(1, 2),
  },
  warningHeader: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  successHeader: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  infoHeader: {
    backgroundColor: theme.palette.info.main,
    color: '#fff',
  },
  statNumber: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  statLabel: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },
  statCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
    position: 'relative',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: theme.shadows[6],
      cursor: 'pointer',
    },
  },
  avatar: {
    backgroundColor: (props) => props.color || theme.palette.primary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(1),
  },
  avatarIcon: {
    fontSize: '2rem',
  },
  mapPreviewContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
    minHeight: 200,
  },
  mapPreviewOverlay: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderTopLeftRadius: theme.shape.borderRadius,
  },
  listItemWithBorder: {
    borderLeft: '4px solid',
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
  progressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  tabRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  priorityHigh: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  priorityMedium: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  priorityLow: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
  },
  badge: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  sectionTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    fontWeight: 500,
  },
  calendarItem: {
    position: 'relative',
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      cursor: 'pointer',
    },
  },
  dateChip: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(0),
    "&:last-child": {
      paddingBottom: 0
    }
  },
  viewMoreButton: {
    margin: theme.spacing(1),
  },
  propertyAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: theme.palette.grey[200],
  },
  issueIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
  overviewSection: {
    marginBottom: theme.spacing(4),
  },
  cardHeaderAction: {
    margin: 0,
  }
}));

const generateDistinctColorMap = (employees) => {
  const colorMap = {};
  const total = employees.length;

  // Generate shuffled hue steps for max visual separation
  const hueSteps = [];
  for (let i = 0; i < total; i++) {
    const hue = Math.round((360 / total) * i);
    hueSteps.push(hue);
  }

  // Shuffle hue order to spread visually similar colors apart
  const visuallyDistinctOrder = [0, 180, 90, 270, 45, 225, 135, 315]; // works great for up to 8
  const hues = hueSteps.map((_, i) => visuallyDistinctOrder[i % visuallyDistinctOrder.length]);

  employees.forEach((emp, index) => {
    const hue = hues[index % hues.length]; // wrap if more than 8
    colorMap[emp._id] = `hsl(${hue}, 70%, 50%)`;
  });

  return colorMap;
};

const IrrigationDashboard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [loading, setLoading] = useState(true);
  const [territories, setTerritories] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [issues, setIssues] = useState([]);
  const [recentChecks, setRecentChecks] = useState([]);
  const [upcomingChecks, setUpcomingChecks] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [propertyMaps, setPropertyMaps] = useState([]);
  const [properties, setProperties] = useState([]);
  const [territoryStats, setTerritoryStats] = useState([]);
  const [employeeColorMap, setEmployeeColorMap] = useState({});
  const [chartData, setChartData] = useState([])

  // Dashboard stats
  const [stats, setStats] = useState({
    totalTerritories: 0,
    totalProperties: 0,
    propertiesWithMaps: 0,
    outstandingIssues: 0,
    openWorkOrders: 0,
    completedChecksThisWeek: 0,
    scheduledChecksNextWeek: 0
  });

  // Tab states
  const [issueTabValue, setIssueTabValue] = useState(0);
  const [checkTabValue, setCheckTabValue] = useState(0);
  
const fetchDashboardData = async () => {
  console.log('Fetch Dashboard Data...')
  setLoading(true);
  try {
    const response = await axios.get(`${props.url}/api/hmi/genericGetRequest?type=IRRIGATION_DASHBOARD`);
    
    if (response?.data?.success && response?.data?.data) {
      const dashboardData = response.data.data;
      console.log('Set our dashboard data...', dashboardData)
      // Set basic data
      setTerritories(dashboardData.existingTerritories || []);
      setEmployees(dashboardData.irrigationTechs || []);
      setIssues(dashboardData.issues || []);
      setWorkOrders(dashboardData.workorders || []);
      setRecentChecks(dashboardData.recentChecks || []);
      setUpcomingChecks(dashboardData.upcomingChecks || []);
      setProperties(dashboardData.properties || []);
      setPropertyMaps(dashboardData.propertiesWithMaps || []);
      setTerritoryStats(dashboardData.territoryStats || []);
      setStats(dashboardData.stats || {});
      setChartData(dashboardData.chartData || [])
      completedWorkordersStatsByEmployee
      // Generate color map for employees if not provided
      if (dashboardData.irrigationTechs && dashboardData.irrigationTechs.length) {
        const colorMap = generateDistinctColorMap(dashboardData.irrigationTechs);
        setEmployeeColorMap(colorMap);
      }
    }
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
  } finally {
    setLoading(false);
  }
};

  React.useEffect(() => {
    fetchDashboardData()
  }, [])

  // Mock data generator functions
  const generateMockData = () => {
    // Properties data
    const properties = [];
    const propertyNames = [
      'Oakwood Estates', 'Highland Gardens', 'Riverside Complex', 
      'Evergreen Meadows', 'Sunny Acres', 'Maple Ridge', 
      'Cedar Heights', 'Pine Valley', 'Willow Creek', 'Blue Horizon'
    ];
    
    for (let i = 0; i < 50; i++) {
      properties.push({
        _id: `prop_${i}`,
        name: propertyNames[i % propertyNames.length] + ` ${Math.floor(i / 10) + 1}`,
        address: `${1000 + i} Main St, Anytown, USA`,
        territoryId: `territory_${i % 5}`,
        hasAssetMap: Math.random() > 0.4,
      });
    }
    
    // Territories
    const territoryNames = ['North', 'South', 'East', 'West', 'Central'];
    const territories = territoryNames.map((name, i) => ({
      _id: `territory_${i}`,
      name: `${name} Territory`,
      employeeId: i < 4 ? `emp_${i}` : null,
      propertyCount: properties.filter(p => p.territoryId === `territory_${i}`).length
    }));
    
    // Employees
    const employees = [];
    const firstNames = ['John', 'Sarah', 'Michael', 'Emma', 'David'];
    const lastNames = ['Smith', 'Johnson', 'Williams', 'Brown', 'Jones'];
    
    for (let i = 0; i < 5; i++) {
      employees.push({
        _id: `emp_${i}`,
        firstName: firstNames[i],
        lastName: lastNames[i],
        position: 'Irrigation Technician',
        territories: territories.filter(t => t.employeeId === `emp_${i}`).map(t => t._id)
      });
    }
    
    // Issues
    const issueTypes = ['Broken Sprinkler', 'Leaking Valve', 'Controller Malfunction', 'Low Water Pressure', 'Misaligned Head'];
    const issues = [];
    
    for (let i = 0; i < 15; i++) {
      const priority = i % 3; // 0 = high, 1 = medium, 2 = low
      const property = properties[Math.floor(Math.random() * properties.length)];
      
      issues.push({
        _id: `issue_${i}`,
        property: property,
        type: issueTypes[i % issueTypes.length],
        description: `${issueTypes[i % issueTypes.length]} in zone ${(i % 8) + 1}`,
        priority: priority === 0 ? 'High' : priority === 1 ? 'Medium' : 'Low',
        reportDate: moment().subtract(Math.floor(Math.random() * 7), 'days').format('YYYY-MM-DD'),
        status: i < 10 ? 'Open' : 'In Progress'
      });
    }
    
    // Recent Checks
    const recentChecks = [];
    for (let i = 0; i < 10; i++) {
      const property = properties[Math.floor(Math.random() * properties.length)];
      const employee = employees[Math.floor(Math.random() * employees.length)];
      
      recentChecks.push({
        _id: `check_${i}`,
        property: property,
        employee: employee,
        date: moment().subtract(i, 'days').format('YYYY-MM-DD'),
        status: 'Completed',
        issues: Math.floor(Math.random() * 3),
        notes: Math.random() > 0.7 ? 'System operating normally' : 'Adjustments made to zones 2 and 4'
      });
    }
    
    // Upcoming Checks
    const upcomingChecks = [];
    for (let i = 0; i < 12; i++) {
      const property = properties[Math.floor(Math.random() * properties.length)];
      const employee = employees[Math.floor(Math.random() * employees.length)];
      
      upcomingChecks.push({
        _id: `upcoming_${i}`,
        property: property,
        employee: employee,
        date: moment().add(i, 'days').format('YYYY-MM-DD'),
        status: 'Scheduled',
        checkType: Math.random() > 0.3 ? 'Routine' : 'Follow-up'
      });
    }
    
    // Work Orders
    const workOrders = [];
    const workOrderTypes = ['Repair', 'Maintenance', 'Installation', 'Adjustment', 'Inspection'];
    
    for (let i = 0; i < 8; i++) {
      const property = properties[Math.floor(Math.random() * properties.length)];
      const employee = employees[Math.floor(Math.random() * employees.length)];
      
      workOrders.push({
        _id: `wo_${i}`,
        property: property,
        type: workOrderTypes[i % workOrderTypes.length],
        description: `${workOrderTypes[i % workOrderTypes.length]} - ${issueTypes[Math.floor(Math.random() * issueTypes.length)]}`,
        assignedTo: employee,
        priority: i % 3 === 0 ? 'High' : i % 3 === 1 ? 'Medium' : 'Low',
        status: 'Open',
        createdDate: moment().subtract(Math.floor(Math.random() * 14), 'days').format('YYYY-MM-DD'),
        scheduledDate: moment().add(Math.floor(Math.random() * 7), 'days').format('YYYY-MM-DD')
      });
    }
    
    // Properties with asset maps
    const propertyMaps = properties.filter(p => p.hasAssetMap);
    
    // Calculate stats
    const stats = {
      totalTerritories: territories.length,
      totalProperties: properties.length,
      propertiesWithMaps: propertyMaps.length,
      outstandingIssues: issues.filter(i => i.status === 'Open' || i.status === 'In Progress').length,
      openWorkOrders: workOrders.length,
      completedChecksThisWeek: recentChecks.filter(c => 
        moment(c.date).isAfter(moment().subtract(7, 'days'))
      ).length,
      scheduledChecksNextWeek: upcomingChecks.filter(c => 
        moment(c.date).isBefore(moment().add(7, 'days'))
      ).length,
    };
    
    return {
      territories,
      employees,
      issues,
      recentChecks,
      upcomingChecks,
      workOrders,
      propertyMaps,
      stats
    };
  };

  // Fetch data from server or use mock data
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       // In a real implementation, you would fetch data from your API
  //       // For now, let's use mock data
  //       const mockData = generateMockData();
        
  //       setTerritories(mockData.territories);
  //       setEmployees(mockData.employees);
  //       setIssues(mockData.issues);
  //       setRecentChecks(mockData.recentChecks);
  //       setUpcomingChecks(mockData.upcomingChecks);
  //       setWorkOrders(mockData.workOrders);
  //       setPropertyMaps(mockData.propertyMaps);
  //       setStats(mockData.stats);
        
  //       // Simulate API delay
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
        
  //     } catch (error) {
  //       console.error('Error fetching dashboard data:', error);
  //       setLoading(false);
  //     }
  //   };
    
  //   fetchData();
  // }, []);
  
  // Handle tab changes
  const handleIssueTabChange = (event, newValue) => {
    setIssueTabValue(newValue);
  };
  
  const handleCheckTabChange = (event, newValue) => {
    setCheckTabValue(newValue);
  };

  // Render functions for dashboard sections
  const renderMetricCards = () => (
    <Grid container spacing={3} className={classes.overviewSection}>
      <Grid item xs={12} md={3} sm={6}>
        <Paper className={classes.paper}>
          <Box className={classes.statCard}>
            <Badge badgeContent={stats.outstandingIssues} color="error" className={classes.badge}>
              <Avatar className={classes.avatar} style={{ backgroundColor: theme.palette.error.main }}>
                <WarningIcon className={classes.avatarIcon} />
              </Avatar>
            </Badge>
            <Typography className={classes.statNumber}>{stats.outstandingIssues}</Typography>
            <Typography className={classes.statLabel}>Outstanding Issues</Typography>
          </Box>
        </Paper>
      </Grid>
      
      <Grid item xs={12} md={3} sm={6}>
        <Paper className={classes.paper}>
          <Box className={classes.statCard}>
            <Avatar className={classes.avatar} style={{ backgroundColor: theme.palette.success.main }}>
              <CheckCircleIcon className={classes.avatarIcon} />
            </Avatar>
            <Typography className={classes.statNumber}>{stats.completedChecksThisWeek}</Typography>
            <Typography className={classes.statLabel}>Checks Completed This Week</Typography>
          </Box>
        </Paper>
      </Grid>
      
      <Grid item xs={12} md={3} sm={6}>
        <Paper className={classes.paper}>
          <Box className={classes.statCard}>
            <Badge badgeContent={stats.scheduledChecksNextWeek} color="primary" className={classes.badge}>
              <Avatar className={classes.avatar} style={{ backgroundColor: theme.palette.info.main }}>
                <ScheduleIcon className={classes.avatarIcon} />
              </Avatar>
            </Badge>
            <Typography className={classes.statNumber}>{stats.scheduledChecksNextWeek}</Typography>
            <Typography className={classes.statLabel}>Upcoming Checks Next Week</Typography>
          </Box>
        </Paper>
      </Grid>
      
      <Grid item xs={12} md={3} sm={6}>
        <Paper className={classes.paper}>
          <Box className={classes.statCard}>
            <Badge badgeContent={stats.openWorkOrders} color="warning" className={classes.badge}>
              <Avatar className={classes.avatar} style={{ backgroundColor: theme.palette.warning.main }}>
                <BuildIcon className={classes.avatarIcon} />
              </Avatar>
            </Badge>
            <Typography className={classes.statNumber}>{stats.openWorkOrders}</Typography>
            <Typography className={classes.statLabel}>Open Work Orders</Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  const renderOutstandingIssues = () => (
    <Paper className={classes.paper}>
      <CardHeader 
        title="Outstanding Issues" 
        className={`${classes.cardHeader} ${classes.warningHeader}`}
        action={
          <Button 
            size="small" 
            color="inherit"
            endIcon={<NavigateNextIcon />}
            component={Link}
            to="/issues"
          >
            View All
          </Button>
        }
        classes={{
          action: classes.cardHeaderAction
        }}
      />
  
      <CardContent className={classes.cardContent}>
        <List component="div">
          {loading ? (
            <Box className={classes.progressWrapper}>
              <CircularProgress />
            </Box>
          ) : (
            issues.slice(0, 10).map(issue => (
              <ListItem
                key={issue._id}
                className={classes.listItemWithBorder}
                style={{ 
                  borderLeftColor: issue.priority === 'High' ? theme.palette.error.main :
                                  issue.priority === 'Medium' ? theme.palette.warning.main :
                                  theme.palette.success.main
                }}
                button
                component={Link}
                to={`/issues/${issue._id}`}
              >
                <ListItemAvatar>
                  <Avatar className={classes.propertyAvatar}>
                    <BusinessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={issue.jobSite?.name || 'Unknown Property'}
                  secondary={
                    <React.Fragment>
                      <WarningIcon fontSize="small" className={classes.issueIcon} />
                      {issue.description || issue.type}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <Chip
                    label={issue.priority}
                    className={classes.chip}
                    size="small"
                    color={
                      issue.priority === 'High' ? 'error' :
                      issue.priority === 'Medium' ? 'warning' : 'success'
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
          )}
        </List>
      </CardContent>
    </Paper>
  );
  
  
  // const renderOutstandingIssues = () => (
  //   <Paper className={classes.paper}>
  //     <CardHeader 
  //       title="Outstanding Issues" 
  //       className={`${classes.cardHeader} ${classes.warningHeader}`}
  //       action={
  //         <Button 
  //           size="small" 
  //           color="inherit"
  //           endIcon={<NavigateNextIcon />}
  //           component={Link}
  //           to="/issues" // Update with your actual route
  //         >
  //           View All
  //         </Button>
  //       }
  //       classes={{
  //         action: classes.cardHeaderAction
  //       }}
  //     />
  //     <Tabs
  //       value={issueTabValue}
  //       onChange={handleIssueTabChange}
  //       indicatorColor="primary"
  //       textColor="primary"
  //       variant="fullWidth"
  //       className={classes.tabRoot}
  //     >
  //       <Tab label="High Priority" />
  //       <Tab label="Medium Priority" />
  //       <Tab label="Low Priority" />
  //     </Tabs>
  //     <CardContent className={classes.cardContent}>
  //       <List component="div">
  //         {loading ? (
  //           <Box className={classes.progressWrapper}>
  //             <CircularProgress />
  //           </Box>
  //         ) : (
  //           issues
  //             .filter(issue => {
  //               return issue.priority === (issueTabValue === 0 ? 'High' : issueTabValue === 1 ? 'Medium' : 'Low');
  //             })
  //             .slice(0, 5)
  //             .map(issue => (
  //               <ListItem
  //                 key={issue._id}
  //                 className={classes.listItemWithBorder}
  //                 style={{ 
  //                   borderLeftColor: issue.priority === 'High' ? theme.palette.error.main :
  //                                   issue.priority === 'Medium' ? theme.palette.warning.main :
  //                                   theme.palette.success.main
  //                 }}
  //                 button
  //                 component={Link}
  //                 to={`/issues/${issue._id}`} // Update with your actual route
  //               >
  //                 <ListItemAvatar>
  //                   <Avatar className={classes.propertyAvatar}>
  //                     <BusinessIcon />
  //                   </Avatar>
  //                 </ListItemAvatar>
  //                 <ListItemText
  //                   primary={issue.jobSite?.name || 'Unknown Property'}
  //                   secondary={
  //                     <React.Fragment>
  //                       <WarningIcon fontSize="small" className={classes.issueIcon} />
  //                       {issue.description || issue.type}
  //                     </React.Fragment>
  //                   }
  //                 />
  //                 <ListItemSecondaryAction>
  //                   <Chip
  //                     label={issue.priority}
  //                     className={classes.chip}
  //                     size="small"
  //                     color={
  //                       issue.priority === 'High' ? 'error' :
  //                       issue.priority === 'Medium' ? 'warning' : 'success'
  //                     }
  //                   />
  //                 </ListItemSecondaryAction>
  //               </ListItem>
  //             ))
  //         )}
  //       </List>
  //     </CardContent>
  //     {!loading && issues.filter(i => 
  //       i.priority === (issueTabValue === 0 ? 'High' : issueTabValue === 1 ? 'Medium' : 'Low')
  //     ).length > 5 && (
  //       <Box className={classes.cardAction}>
  //         <Button
  //           variant="outlined"
  //           color="primary"
  //           size="small"
  //           className={classes.viewMoreButton}
  //           component={Link}
  //           to="/issues" // Update with your actual route
  //         >
  //           View More
  //         </Button>
  //       </Box>
  //     )}
  //   </Paper>
  // );

  const renderIrrigationChecks = () => (
    <Paper className={classes.paper}>
      <CardHeader 
        title={checkTabValue === 0 ? "Recent Irrigation Checks" : "Upcoming Irrigation Checks"} 
        className={checkTabValue === 0 ? `${classes.cardHeader} ${classes.successHeader}` : `${classes.cardHeader} ${classes.infoHeader}`}
        action={
          <Button 
            size="small" 
            color="inherit"
            endIcon={<NavigateNextIcon />}
            component={Link}
            to={checkTabValue === 0 ? "/recent-checks" : "/upcoming-checks"} // Update with your actual routes
          >
            View All
          </Button>
        }
        classes={{
          action: classes.cardHeaderAction
        }}
      />
      <Tabs
        value={checkTabValue}
        onChange={handleCheckTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className={classes.tabRoot}
      >
        <Tab label="Recently Completed" />
        <Tab label="Upcoming" />
      </Tabs>
      <CardContent className={classes.cardContent}>
        <List component="div">
          {loading ? (
            <Box className={classes.progressWrapper}>
              <CircularProgress />
            </Box>
          ) : (
            (checkTabValue === 0 ? recentChecks : upcomingChecks)
              .slice(0, 5)
              .map(check => (
                <ListItem
                  key={check._id}
                  button
                  component={Link}
                  to={`/checks/${check._id}`} // Update with your actual route
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: checkTabValue === 0 ? theme.palette.success.light : theme.palette.info.light }}>
                      {checkTabValue === 0 ? <CheckCircleIcon /> : <EventIcon />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={check.jobSite?.name || 'Unknown Property'}
                    secondary={
                      checkTabValue === 0 
                        ? `Completed on ${moment(check.work_complete_at).format('MMM D')} by ${check.work_completed_by?.firstName || ''} ${check.work_completed_by?.lastName || ''}` 
                        : `Scheduled for ${moment(check.scheduledDate).format('MMM D')} ${check?.crew?.name ? `${check.crew.name}${check.crew?.foremanName ? ` (${check.crew.foremanName})` : ''}` : '' }`
                    }
                  />
                  <ListItemSecondaryAction>
                    <Chip
                      label={checkTabValue === 0 ? moment(check.work_complete_at).format('MMM D') : moment(check.scheduledDate).fromNow()}
                      className={classes.dateChip}
                      size="small"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))
          )}
        </List>
      </CardContent>
      {!loading && (checkTabValue === 0 ? recentChecks : upcomingChecks).length > 5 && (
        <Box className={classes.cardAction}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.viewMoreButton}
            component={Link}
            to={checkTabValue === 0 ? "/recent-checks" : "/upcoming-checks"} // Update with your actual routes
          >
            View More
          </Button>
        </Box>
      )}
    </Paper>
  );
  
  const renderWorkOrders = () => (
    <Paper className={classes.paper}>
      <CardHeader 
        title="Open Irrigation Work Orders" 
        className={`${classes.cardHeader}`}
        action={
          <Button 
            size="small" 
            color="inherit"
            endIcon={<NavigateNextIcon />}
            component={Link}
            to="/work-orders" // Update with your actual route
          >
            View All
          </Button>
        }
        classes={{
          action: classes.cardHeaderAction
        }}
      />
      <CardContent className={classes.cardContent}>
        {loading ? (
          <Box className={classes.progressWrapper}>
            <CircularProgress />
          </Box>
        ) : (
          <List component="div">
            {workOrders.slice(0, 5).map(order => (
              <ListItem
                key={order._id}
                className={classes.listItemWithBorder}
                style={{ 
                  borderLeftColor: order.priority === 'High' ? theme.palette.error.main :
                                  order.priority === 'Medium' ? theme.palette.warning.main :
                                  theme.palette.success.main
                }}
                button
                component={Link}
                to={`/work-orders/${order._id}`} // Update with your actual route
              >
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: theme.palette.warning.light }}>
                    <BuildIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={order.jobSite.name}
                  secondary={
                    <React.Fragment>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {order.type}
                      </Typography>
                      {` - Scheduled: ${moment(order.scheduledDate).format('MMM D')}`}
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <Chip
                    label={getCrewName(order.crew)}
                    className={classes.chip}
                    size="small"
                    color={
                      order.priority === 'High' ? 'error' :
                      order.priority === 'Medium' ? 'warning' : 'success'
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
      {!loading && workOrders.length > 5 && (
        <Box className={classes.cardAction}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.viewMoreButton}
            component={Link}
            to="/work-orders" // Update with your actual route
          >
            View More
          </Button>
        </Box>
      )}
    </Paper>
  );
  
  const getCrewName = (crew) => {
    if (!crew) return ''
    // order?.crew?.name
    const {
      name,
      foremanName
    } = crew
    if (!name && !foremanName) return ''
    let crewName = `${name}${foremanName ? ` (${foremanName})` : '' }`
    return crewName
  }

  const renderTerritoryStats = () => {
    // Prepare data for charts
    const territoryData = territories.map(territory => ({
      name: territory.name,
      properties: territory.propertyCount,
      employee: employees.find(e => e._id === territory.employeeId)
        ? `${employees.find(e => e._id === territory.employeeId).firstName} ${employees.find(e => e._id === territory.employeeId).lastName}`
        : 'Unassigned'
    }));
    
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <Paper style={{ padding: theme.spacing(1) }}>
            <Typography variant="body2" color="textPrimary">
              {label}
            </Typography>
            <Typography variant="body2">
              {`${payload[0].value} Properties`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`Tech: ${payload[0].payload.employee}`}
            </Typography>
          </Paper>
        );
      }
      return null;
    };
    
    return (
      <Paper className={classes.paper}>
        <CardHeader 
          title="Properties Per Territory" 
          className={classes.cardHeader}
          action={
            <Button 
              size="small" 
              color="inherit"
              endIcon={<NavigateNextIcon />}
              component={Link}
              to="/territory-map" // Update with your actual route
            >
              View Map
            </Button>
          }
          classes={{
            action: classes.cardHeaderAction
          }}
        />
        <CardContent>
          {loading ? (
            <Box className={classes.progressWrapper}>
            <CircularProgress />
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height={250}>
            <BarChart
              data={territoryData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 60
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="name" 
                height={60}
                tick={props => {
                  const { x, y, payload } = props;
                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fill="#666"
                        transform="rotate(-35)"
                      >
                        {payload.value}
                      </text>
                    </g>
                  );
                }}
              />
              <YAxis allowDecimals={false} />
              <RechartsTooltip content={<CustomTooltip />} />
              <Bar dataKey="properties" fill={theme.palette.primary.main} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Paper>
  );
};

const renderAssetMapStats = () => {
  // Prepare data for pie chart
  const assetMapData = [
    { name: 'Properties with Maps', value: stats.propertiesWithMaps },
    { name: 'Properties without Maps', value: stats.totalProperties - stats.propertiesWithMaps }
  ];
  
  const COLORS = [theme.palette.primary.main, theme.palette.grey[300]];
  
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    return percent > 0.1 ? (
      <text x={x} y={y} fill="#fff" textAnchor="middle" dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    ) : null;
  };
  
  return (
    <Paper className={classes.paper}>
      <CardHeader 
        title="Properties with Asset Maps" 
        className={classes.cardHeader}
        action={
          <Button 
            size="small" 
            color="inherit"
            endIcon={<NavigateNextIcon />}
            component={Link}
            to="/asset-maps" // Update with your actual route
          >
            Create Maps
          </Button>
        }
        classes={{
          action: classes.cardHeaderAction
        }}
      />
      <CardContent>
        {loading ? (
          <Box className={classes.progressWrapper}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={assetMapData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {assetMapData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <RechartsTooltip />
              </PieChart>
            </ResponsiveContainer>
            <Box textAlign="center" mt={2}>
              <Typography variant="body1">
                {stats.propertiesWithMaps} of {stats.totalProperties} properties have asset maps
              </Typography>
              <Typography variant="body2" color="textSecondary">
                ({((stats.propertiesWithMaps / stats.totalProperties) * 100).toFixed(1)}% coverage)
              </Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Paper>
  );
};

const renderTerritoryMap = () => (
  <Paper className={classes.paper}>
    <CardHeader 
      title="Territory Map" 
      className={classes.cardHeader}
      action={
        <Button 
          size="small" 
          color="inherit"
          endIcon={<NavigateNextIcon />}
          component={Link}
          to="/irrigationareas"
        >
          Full Map
        </Button>
      }
      classes={{
        action: classes.cardHeaderAction
      }}
    />
    <CardContent style={{ padding: 0, height: 300 }}>
      <TerritoryMapPreview 

      />
    </CardContent>
  </Paper>
);

// const renderTerritoryMapOLD = () => (
//   <Paper className={classes.paper}>
//     <CardHeader 
//       title="Territory Map" 
//       className={classes.cardHeader}
//     />
//     <CardContent style={{ padding: 0, height: '100%' }}>
//       <Box className={classes.mapPreviewContainer}>
//         <div style={{ 
//           height: '100%', 
//           minHeight: 250,
//           background: `linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), 
//                        url('https://via.placeholder.com/800x400?text=Map+Background')`,
//           backgroundSize: 'cover',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           flexDirection: 'column',
//           padding: theme.spacing(2)
//         }}>
//           <MapIcon style={{ fontSize: 60, color: theme.palette.primary.main, marginBottom: theme.spacing(2) }} />
//           <Typography variant="body1" align="center" gutterBottom>
//             Interactive Territory Map
//           </Typography>
//           <Typography variant="body2" color="textSecondary" align="center">
//             View territories and their assigned properties
//           </Typography>
//         </div>
//         <Box className={classes.mapPreviewOverlay}>
//           <Button 
//             variant="contained" 
//             color="primary" 
//             size="small"
//             component={Link}
//             to="/irrigationareas"
//             endIcon={<NavigateNextIcon />}
//           >
//             Open Full Map
//           </Button>
//         </Box>
//       </Box>
//     </CardContent>
//   </Paper>
// );

const renderTrendChart = () => {
  // Check if we have data to display
  if (loading) {
    return (
      <Box className={classes.progressWrapper}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (!chartData || chartData.length === 0) {
    return (
      <Box className={classes.noDataWrapper}>
        <Typography variant="body2" color="textSecondary">
          No data available for the selected period
        </Typography>
      </Box>
    );
  }

  // Get all employee names from the first data point
  // Exclude the 'date' field
  const employeeNames = Object.keys(chartData[0]).filter(key => key !== 'date');
  
  // Generate a color palette - using a mix of theme colors
  const generateColorPalette = (count) => {
    const colorOptions = [
      { main: theme.palette.primary.main, light: theme.palette.primary.light },
      { main: theme.palette.secondary.main, light: theme.palette.secondary.light },
      { main: theme.palette.success.main, light: theme.palette.success.light },
      { main: theme.palette.info.main, light: theme.palette.info.light },
      { main: theme.palette.warning.main, light: theme.palette.warning.light },
      { main: theme.palette.error.main, light: theme.palette.error.light }
    ];
    
    // If we need more colors than available in the theme, generate additional colors
    const result = [];
    for (let i = 0; i < count; i++) {
      if (i < colorOptions.length) {
        result.push(colorOptions[i]);
      } else {
        // Use a modulo to cycle through colors with different opacities
        const baseColor = colorOptions[i % colorOptions.length];
        const opacity = 0.7 - (Math.floor(i / colorOptions.length) * 0.15);
        result.push({
          main: baseColor.main,
          light: baseColor.light + Math.floor(opacity * 100).toString(16)
        });
      }
    }
    return result;
  };
  
  const colors = generateColorPalette(employeeNames.length);
  
  return (
    <Paper className={classes.paper}>
      <CardHeader 
        title="Completed Workorders by Employee (30 Days)" 
        className={classes.cardHeader}
      />
      <CardContent>
        <ResponsiveContainer width="100%" height={250}>
          <AreaChart
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date" 
              tick={{ fontSize: 10 }}
              interval={6}
            />
            <YAxis allowDecimals={false} />
            <RechartsTooltip />
            <Legend />
            
            {/* Dynamically render Area components for each employee */}
            {employeeNames.map((employeeName, index) => {
              // Skip rendering if employee name is empty or "Unassigned"
              if (!employeeName.trim() || employeeName === "Unassigned") {
                return null;
              }
              
              return (
                <Area 
                  key={employeeName}
                  type="monotone" 
                  dataKey={employeeName} 
                  name={employeeName.length > 15 ? employeeName.substring(0, 15) + '...' : employeeName} 
                  stroke={colors[index].main} 
                  fill={colors[index].light} 
                  stackId="1" // Stack the areas on top of each other
                />
              );
            })}
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Paper>
  );
};

const renderTrendChartOle = () => {
  // Check if we have data to display
  if (loading) {
    return (
      <Box className={classes.progressWrapper}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (!chartData || chartData.length === 0) {
    return (
      <Box className={classes.noDataWrapper}>
        <Typography variant="body2" color="textSecondary">
          No data available for the selected period
        </Typography>
      </Box>
    );
  }
  
  // Get all crew names from the first data point
  // Exclude the 'date' field
  const crewNames = Object.keys(chartData[0]).filter(key => key !== 'date');
  
  // Generate colors for each crew - using a combination of theme colors
  // Adjust this as needed based on your theme
  const colorOptions = [
    theme.palette.primary,
    theme.palette.secondary,
    theme.palette.success,
    theme.palette.info,
    theme.palette.warning,
    theme.palette.error
  ];
  
  return (
    <Paper className={classes.paper}>
      <CardHeader 
        title="Completed Workorders by Crew (30 Days)" 
        className={`${classes.cardHeader} ${classes.infoHeader}`}
      />
      <CardContent>
        <ResponsiveContainer width="100%" height={250}>
          <AreaChart
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date" 
              tick={{ fontSize: 10 }}
              interval={6}
            />
            <YAxis allowDecimals={false} />
            <RechartsTooltip />
            <Legend />
            
            {/* Dynamically render Area components for each crew */}
            {crewNames.map((crewName, index) => {
              const colorIndex = index % colorOptions.length;
              const color = colorOptions[colorIndex];
              
              return (
                <Area 
                  key={crewName}
                  type="monotone" 
                  dataKey={crewName} 
                  name={crewName} 
                  stroke={color.main} 
                  fill={color.light} 
                  stackId="1" // Stack the areas on top of each other
                />
              );
            })}
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Paper>
  );
};

const renderTrendChartOLD = () => {
  // Generate some mock data for the trend chart
  const trendData = [];
  // for (let i = 30; i >= 0; i--) {
  //   const date = moment().subtract(i, 'days');
  //   trendData.push({
  //     date: date.format('MMM D'),
  //     checks: Math.floor(Math.random() * 5) + 1,
  //     issues: Math.floor(Math.random() * 3),
  //   });
  // }
  console.log('renderTrendChart....', chartData)
  
  return (
    <Paper className={classes.paper}>
      <CardHeader 
        title="Monthly Activity" 
        className={`${classes.cardHeader} ${classes.infoHeader}`}
      />
      <CardContent>
        {loading ? (
          <Box className={classes.progressWrapper}>
            <CircularProgress />
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height={250}>
            <AreaChart
              data={trendData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                tick={{ fontSize: 10 }}
                interval={6}
              />
              <YAxis allowDecimals={false} />
              <RechartsTooltip />
              <Area type="monotone" dataKey="checks" name="Checks" stroke={theme.palette.success.main} fill={theme.palette.success.light} />
              <Area type="monotone" dataKey="issues" name="Issues" stroke={theme.palette.error.main} fill={theme.palette.error.light} />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Paper>
  );
};

// Main render
return (
  <div className={classes.root}>
    <Typography variant="h4" component="h1" className={classes.dashboardTitle}>
      Irrigation Dashboard
    </Typography>
    
    {/* Key Metrics Cards */}
    {renderMetricCards()}
    
    {/* First Row - Issues and Checks */}
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {renderOutstandingIssues()}
      </Grid>
      <Grid item xs={12} md={6}>
        {renderIrrigationChecks()}
      </Grid>
    </Grid>
    
    {/* Second Row - Work Orders and Territory */}
    <Grid container spacing={3} style={{ marginTop: theme.spacing(3) }}>
      <Grid item xs={12} md={6}>
        {renderWorkOrders()}
      </Grid>
      <Grid item xs={12} md={6}>
        {renderTerritoryStats()}
      </Grid>
    </Grid>
    
    {/* Third Row - Map and Asset Maps */}
    <Grid container spacing={3} style={{ marginTop: theme.spacing(3) }}>
      <Grid item xs={12} md={8}>
        {renderTerritoryMap()}
      </Grid>
      <Grid item xs={12} md={4}>
        {renderAssetMapStats()}
      </Grid>
    </Grid>
    
    {/* Fourth Row - Trend Chart */}
    <Grid container spacing={3} style={{ marginTop: theme.spacing(3) }}>
      <Grid item xs={12}>
        {renderTrendChart()}
      </Grid>
    </Grid>
    {/* <Grid container spacing={3} style={{ marginTop: theme.spacing(3) }}>
      <Grid item xs={12}>
        <WorkorderTrendChart chartData={chartData} loading={loading} theme={theme} classes={classes} />
      </Grid>
    </Grid> */}

    
  </div>
);
};

const mapStateToProps = (state) => {
return {
  url: state.url,
};
};

export default connect(mapStateToProps)(IrrigationDashboard);