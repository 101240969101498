import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, ButtonBase, IconButton }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PreviewGallery from './PreviewGallery'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  imageContainer: {
    position: 'relative',
    width: '80vw',
    height: '80vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: 'none',
  },
  mainImage: {
    cursor: 'grab', // Changes the cursor to indicate movability
    position: 'relative', // Needed for positioning based on state
    userSelect: 'none', // Prevents image selection on drag
    willChange: 'transform', // Optimizes animations
  },
  thumbnailContainer: {
    display: 'flex',
    overflowX: 'auto',
    padding: theme.spacing(1),
  },
  thumbnail: {
    margin: theme.spacing(0.5),
    width: 100,
    height: 100,
    objectFit: 'cover',
  },
  navButton: {
    alignSelf: 'center',
  },
  leftNav: {
    left: theme.spacing(2),
  },
  rightNav: {
    right: theme.spacing(2),
  },
  imageContainer: {
    width: '100%', // Ensures the container fills the parent
    overflow: 'hidden', // Prevents image overflow outside the container
    textAlign: 'center', // Centers the image
  },
  image: {
    width: '100%',
    transition: 'transform 0.2s', // Smooth transform effect
  },
}));

const LazyImage = ({ src, alt, scale }) => {
  const [imageSrc, setImageSrc] = useState('');
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const classes = useStyles();

  React.useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setImageSrc(src);
  }, [src]);

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const newX = e.clientX - startPos.x;
      const newY = e.clientY - startPos.y;
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  return (
    <img
      src={imageSrc || 'path/to/loader.gif'}
      alt={alt}
      style={{
        transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
        maxWidth: '100%',
        height: 'auto'
      }}
      className={classes.mainImage}
      onMouseDown={handleMouseDown}
      onMouseMove={dragging ? handleMouseMove : null}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    />
  );
};

const defaultInputValues = {
  projectName: ''
}

const categoryOptions = [
  'Vendor Quotes',
  'Vendor Invoice',
  'Customer Invoice',
  'Purchase Order',
  'Photos',
  'Plans',
  'Estimates',
  'Other'
]

const DisplayImageComponent = (props) => {
  console.log('DisplayImageComponent Props: 130', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState({})
  const [scale, setScale] = useState(1); // Starts with no zoom
  const [currentImage, setCurrentImage] = useState(0);

  React.useEffect(() => {
    if (props.item && props.item._id && ((activeItem && activeItem._id && activeItem._id !== props.item._id) || !activeItem || activeItem === {})) setActiveItem(props.item)
  }, [props.item])

  const handleZoomIn = () => {
    setScale(scale * 1.1); // Increases scale by 10%
  };

  const handleZoomOut = () => {
    setScale(scale / 1.1); // Decreases scale by 10%
  };

  const resetZoom = () => {
    setScale(1); // Resets zoom to original
  };

  const handleCloseModal = () => {
    props.closeModal()
  }


  let imageURL = ''
  //   if (activeItem && activeItem.url) {
  //     imageURL = activeItem.url
  //   if (!currentImage.includes('https')) imageURL = `https://${activeItem.url}`
  // }
  if (props.images && props.images.length) {
    imageURL = props.images[currentImage].url
    // console.log('IMage url:', imageURL)
    if (imageURL && typeof(imageURL) === 'string' && !imageURL.includes('https')) imageURL = `https://${props.images[currentImage].url}`
  }
  // console.log(imageURL)
  return (
    <div className={classes.root}>
      <Dialog open={props.open} fullScreen={true} onClose={props.closeModal} maxWidth='lg' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">See A Big Picture</DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button onClick={handleZoomIn} variant="contained" color="primary">Zoom In</Button>
              <Button onClick={handleZoomOut} variant="contained" color="secondary">Zoom Out</Button>
              <Button onClick={resetZoom} variant="contained">Reset</Button>
            </Grid>
          </Grid>
         

          <DialogContent >
            <DialogContentText>
             Your Image
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.imageContainer}>
                <LazyImage src={imageURL} alt="Displayed Image" scale={scale} />
                {/* {activeItem && activeItem.url && activeItem.url.includes('https') ? (
                  <img
                    src={activeItem.url}
                    style={{ transform: `scale(${scale})`, maxWidth: '100%', height: 'auto' }}
                    className={classes.image}
                    alt="Big Picture"
                  />
                ) : null } */}
              </Grid>
             
            </Grid>

            {/* <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
              {
                (photoURL && photoURL.includes('https')) ? <img src={photoURL} style={{ maxWidth: '100%', height: 'auto', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} alt="Big Picture" />
               : null
              }
              </Grid>
            </Grid> */}
          </DialogContent>
          <DialogActions>
            <PreviewGallery images={props.images} item={activeItem} setItem={setActiveItem} currentImage={currentImage} setCurrentImage={setCurrentImage} />
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
            {/* <Button color="primary" disabled={loading} onClick={handleSave}>
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button> */}
          </DialogActions>
        </Dialog>
    </div>
  )
}

  const mapStateToProps = (state) => {
    // console.log('Map state to props 395', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      projects: state.projects
    }
  }
  
  export default connect(mapStateToProps)(DisplayImageComponent)